import { useState, useRef, useEffect } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons';
import { SpinnerInfinity } from 'spinners-react';
import Axios, { AxiosError } from 'axios';
import { useNavigate } from "react-router-dom";
import { CSVLink } from 'react-csv';
import jwtDecode from "jwt-decode";

export default function Trace({ domlecServer, updateIsAuthenticated }) {
    const [loading, setLoading] = useState(false); //determines when to show the spinning/loading wheel
    const [documentId, setDocumentId] = useState(''); //unique key of document in mongo db
    const [document, setDocument] = useState(''); //stores retrieved mongo data
    const documentIdRef = useRef(null);
    const resultMessageRef = useRef(null);
    const [searchAvailable, setSearchAvailable] = useState(false)
    const navigate = useNavigate();


    function handleEnterKeyPress(event) {
        if (event.key === 'Enter' && searchAvailable) {
            handleClickP()
        }
    }

    function epochToDateString(epoch) {
        const date = new Date(epoch * 1000); // Convert epoch to milliseconds
        const day = date.getDate().toString().padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear().toString();
        const hours = date.getHours() % 12 || 12;
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = date.getHours() >= 12 ? 'pm' : 'am';
        return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
    }

    function handleClickP() {
        //disable the search button while we are searching....i.e. don't allow repeated pressing of the button
        setSearchAvailable(false)
        setLoading(true);

        const token = localStorage.getItem('token');

        const options = {
            method: 'POST',
            url: `${domlecServer}/document`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: {
                recordId: documentId
            }
        };

        Axios.request(options).then((response) => {
            //console.log(`response status = ${response.status}`)

            if (response.status === 200) {
                const formatedDateTime = epochToDateString(response.data.result.transactionTime);
                //console.log(`formatted date is ${formatedDateTime} `)

                setDocument({
                    name: response.data.result.name,
                    email: response.data.result.email,
                    amount: parseFloat(response.data.result.amount).toFixed(2),
                    cardNumber: response.data.result.cardNumber,
                    serialnumber: response.data.result.serialnumber,
                    account: response.data.result.account,
                    approvalCode: response.data.result.approvalCode,
                    processorReferenceNumber: response.data.result.processorReferenceNumber,
                    tdateFormatted: formatedDateTime,
                    transactionTime: response.data.result.transactionTime,
                    transactionResult: response.data.result.transactionResult,
                    orderId: response.data.result.orderId,
                    errorMessage: response.data.result.errorMessage,
                    cardSuccess: response.data.result.cardSuccess,
                    domlecSuccess: response.data.result.domlecSuccess,
                    domlecMessage: response.data.result.domlecMessage,
                    domlecStatus: response.data.result.domlecStatus,
                    domlecTransaction: response.data.result.domlecTransaction,
                })
            }
            else if (response.status === 204) {
                resultMessageRef.current.innerHTML = 'Transaction Not Found';
            }
            else { //token expired
                localStorage.removeItem('token');
                updateIsAuthenticated(false)
                navigate('/login') //redirect to the login page
            }
            setLoading(false);
        }).catch((AxiosError) => {
            localStorage.removeItem('token');
            updateIsAuthenticated(false)
            navigate('/login') //redirect to the login page
            console.log(`Axios Error: ${AxiosError}`)
        }).catch((error) => {
            console.error('failed ', error);
            setLoading(false);
            resultMessageRef.current.innerHTML = 'Error accessing data contact IT Suppoprt';
        });
        setSearchAvailable(true)

    }


    function handleInputChange(event) {

        resultMessageRef.current.innerHTML = '';

        setDocumentId(event.target.value.replace(/[^0-9,a-f,A-F]/g, "")) //allow valid hex characters
        setDocument(false)

        const tempDoc = documentIdRef.current.value
        if (tempDoc.length === 24) {//mongodb _id is a 24 character hex string
            setSearchAvailable(true)
        }
        else {//not a valid mongodb _id (<24 characters)
            setSearchAvailable(false)
        }

    }

    useEffect(() => {
        let decodedToken
        try {
            decodedToken = jwtDecode(localStorage.getItem('token'));
        } catch (error) {
        }
        if (decodedToken.changeRequired) {
            //console.log(`password update required ${changeRequired}`)
            navigate('/password')
        }
    });


    return (
        <>
            <div
            className="overflow-transactions"
                onKeyDown={handleEnterKeyPress}
            >
                <div className='row'>
                    <div className='twelve columns'>
                        <h4>TRANSACTION DETAILS</h4>
                        <h5>Use this form to retrieve the database data based on the transaction reference number</h5>
                    </div>
                    <div className="twelve columns">
                        <label >Enter the transaction reference number </label>
                        <input
                            className='u-full-width'
                            ref={documentIdRef}
                            maxLength={24}
                            name="documentId"
                            type='text'
                            value={documentId}
                            onInput={handleInputChange}
                            text="text"
                            autoFocus required
                        />
                        {
                            searchAvailable
                                ? (
                                    <button
                                        className="button-primary"
                                        onClick={handleClickP}>
                                        <FontAwesomeIcon color='white' icon={faSearch} />
                                        <span>TX Search</span>
                                    </button>
                                )
                                : (
                                    <button
                                        disabled
                                        className="button-primary"
                                        onClick={handleClickP}>
                                        <FontAwesomeIcon color='white' icon={faSearch} />
                                        <span>TX Search</span>
                                    </button>
                                )

                        }
                        {
                            document['name'] !== undefined
                                ?

                                <span className=" u-pull-right button-primary button-primary-link">
                                    <CSVLink
                                        data={[document]}
                                        filename={'data.csv'}
                                        target='#'
                                    >
                                        <FontAwesomeIcon color='white' icon={faDownload} />

                                    </CSVLink>
                                </span>
                                : ''
                        }

                        <span className='spinner'>
                            <SpinnerInfinity enabled={loading} size={50} thickness={180} speed={100} color="#36ad47" secondaryColor="rgba(0, 0, 0, 0.44)" />
                        </span>
                        <span
                            className="transaction-not-found"
                            ref={resultMessageRef}
                        ></span>
                    </div>
                    {document['name'] !== undefined
                        ?
                        <>
                            <div className="row">
                                <div className="twelve columns">
                                    <table>
                                        <tr>
                                            <th colSpan={2}>
                                                Card processor details
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>Name</td>
                                            <td>{document.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>{document.email}</td>
                                        </tr>
                                        <tr>
                                            <td>Card</td>
                                            <td>{document.cardNumber}</td>
                                        </tr>
                                        <tr>
                                            <td>Amount</td>
                                            <td>${document.amount}</td>
                                        </tr>
                                        <tr>
                                            <td>Tx Result</td>
                                            <td>{document.transactionResult}</td>
                                        </tr>
                                        <tr>
                                            <td>Processor Ref</td>
                                            <td>{document.processorReferenceNumber}</td>
                                        </tr>
                                        <tr>
                                            <td>Approval Code</td>
                                            <td>{document.approvalCode}</td>
                                        </tr>
                                        <tr>
                                            <td>TX Date</td>
                                            <td>{document.tdateFormatted}</td>
                                        </tr>
                                        <tr>
                                            <td>Order Id</td>
                                            <td>{document.orderId}</td>
                                        </tr>
                                        <tr>
                                            <th colSpan={2}>
                                                Domlec details
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>Meter No</td>
                                            <td>{document.serialnumber}</td>
                                        </tr>
                                        <tr>
                                            <td>Account</td>
                                            <td>{document.account}</td>
                                        </tr>
                                        <tr>
                                            <td>Domlec Tx Ref</td>
                                            <td>{document.domlecTransaction}</td>
                                        </tr>
                                        <tr>
                                            <td>Domlec Tx Status</td>
                                            <td>{document.domlecStatus}</td>
                                        </tr>
                                        <tr>
                                            <td>Domlec Tx Message</td>
                                            <td>{document.domlecMessage}</td>
                                        </tr>

                                    </table>
                                </div>
                            </div>
                        </>
                        : ''
                    }
                </div>
            </div>
        </>


    )
}