
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import Collapsible from 'react-collapsible';

export default function CollapsibleResults(document) {
    const [isOpen, setIsOpen] = useState(false);

    function epochToDateString(epoch) {
        const date = new Date(epoch * 1000); // Convert epoch to milliseconds
        const day = date.getDate().toString().padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear().toString();
        const hours = date.getHours() % 12 || 12;
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = date.getHours() >= 12 ? 'pm' : 'am';
        return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
    }

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Collapsible
            trigger={
                <div onClick={handleToggle}>
                    {
                        isOpen
                            ?
                            <FontAwesomeIcon color='#2878be' icon={faAngleDoubleDown} />
                            :
                            <FontAwesomeIcon color='#2878be' icon={faAngleDoubleRight} />
                    }
                    <span className="result-list">{document.pos} Tx Ref {document._id}</span>
                    <td>{epochToDateString(document.transactionTime)}</td>
                    <td>${parseFloat(document.amount).toFixed(2)}</td>
                </div>}
        >
            <table>
                <tr>
                    <th colspan='2'>
                        Card processing details
                    </th>
                </tr>
                <tr>
                    <td>Name</td>
                    <td>{document.name}</td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>{document.email}</td>
                </tr>
                <tr>
                    <td>Card</td>
                    <td>{document.cardNumber}</td>
                </tr>
                <tr>
                    <td>Amount</td>
                    <td>${parseFloat(document.amount).toFixed(2)}</td>
                </tr>
                <tr>
                    <td>Tx Result</td>
                    <td>{document.transactionResult}</td>
                </tr>
                <tr>
                    <td>Processor Ref</td>
                    <td>{document.processorReferenceNumber}</td>
                </tr>
                <tr>
                    <td>Approval Code</td>
                    <td>{document.approvalCode}</td>
                </tr>
                <tr>
                    <td>TX Date</td>
                    <td>{document.tdateFormatted}</td>
                </tr>
                <tr>
                    <td>Order Id</td>
                    <td>{document.orderId}</td>
                </tr>
                <tr>
                    <th colspan='2'>
                        Domlec details
                    </th>
                </tr>
                <tr>
                    <td>Meter No</td>
                    <td>{document.serialnumber}</td>
                </tr>
                <tr>
                    <td>Account</td>
                    <td>{document.account}</td>
                </tr>
                <tr>
                    <td>Domlec Tx Ref</td>
                    <td>{document.domlecTransaction}</td>
                </tr>
                <tr>
                    <td>Domlec Tx Status</td>
                    <td>{document.domlecStatus}</td>
                </tr>
                <tr>
                    <td>Domlec Tx Message</td>
                    <td>{document.domlecMessage}</td>
                </tr>

            </table>
        </Collapsible>
    );
}