import { useRef, useState } from 'react'; import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, } from '@fortawesome/free-solid-svg-icons';
import ReCAPTCHA from 'react-google-recaptcha';
import { SpinnerInfinity } from 'spinners-react';

export default function ContactUs({ updateProgressFromParent, domlecServer,
    card, updateCardFromParent, updateDomlecFromParent }) {
    /*
    This component will display the contact us email form
    */


    const captchaCommentRef = useRef(null)
    const nameRef = useRef(null)
    const sendButtonRef = useRef(null)
    const emailRef = useRef(null)
    const messageRef = useRef(null)
    const sitekey = `${process.env.REACT_APP_CAPTCHA_SITE_KEY}`; /* CAPTCHA KEY */
    const [sendButton, setSendButton] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    const [emailSuccess, setEmailSuccess] = useState(false)
    const [loading, setLoading] = useState(false);
    const [recaptcha, setRecaptcha] = useState('')


    function disableSending() {
        sendButtonRef.current.disabled = true //disable the send email button
        setSendButton(false)
    }

    function enableSending() {
        sendButtonRef.current.disabled = false //renable the send email button
        setSendButton(true)
    }

    function handleSubmit() {

        if (recaptcha) {
            setLoading(true)

            const options = {
                method: 'POST',
                url: `${domlecServer}/contact`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    name: nameRef.current.value,
                    email: emailRef.current.value,
                    message: messageRef.current.value
                }
            };

            Axios.request(options).then((response) => {
                if (response.data !== undefined && response.data['status'] === 'OK') {
                    setEmailSent(true)
                    setEmailSuccess(true)
                }
                else if (response.data !== undefined && response.data['status'] === 'FAILED') {
                    setEmailSent(true)
                    setEmailSuccess(false)
                }
                setLoading(false)
            }).catch((error) => {
                console.error(error);
                setLoading(false)
                alert('Unable to send email...try again later');
            });

            /*
            setTimeout(() => {
                updateCardFromParent({}) //clear existing card data
                updateDomlecFromParent({}) //clear existing domlec data
                updateProgressFromParent({ step0: true }) //goto homepage
            }, 6000) //reload page (back to the beginning after this amount of seconds)
            */

        }
        else {
            captchaCommentRef.current.className = 'show comment u-pull-right'

            setTimeout(() => {
                captchaCommentRef.current.className = 'comment'
            }, 3000)

        }
    }

    function validateEmail() {
        //must have an @ and a .
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(emailRef.current.value)
    }

    function handleInputChange(event) {
        switch (event.target.name) {
            case "Name": {
                updateCardFromParent({ ...card, name: event.target.value })
                break
            }
            case "Email": {
                updateCardFromParent({ ...card, email: event.target.value })
                break
            }
            case "Message": {
                updateCardFromParent({ ...card, combinedMessage: event.target.value })
                break
            }
            default:

        }
        //now validate the data fields AND the captcha result before enabling the send button
        if (nameRef.current.value.length > 1 && validateEmail(emailRef.current.value) &&
            messageRef.current.value.length > 1) {
            enableSending();
        }
        else {
            disableSending();
        }

    }


    function handleRecaptchaChange(token) {
        setRecaptcha(token);
    }

    return (
        <>
            {
                emailSent ? (
                    emailSuccess ?
                        <h5>Thank you for your inquiry.  Someone will get back to you shortly.</h5 >
                        :
                        <h5> There was an error sending the email, contact Domlec Customer Support via telephone</h5>

                )
                    :
                    <div className='row overflow-transactions'>
                        <div className='twelve columns'>
                            <h4>CONTACT INFORMATION</h4>
                            <h5>
                                <div>Contact us at: 255-6118 / 255 6024 from 8am to 4am, email: paugsupport@domlec.dm</div>
                                <p></p>
                                <div>Or use this form to contact Domlec Customer Support</div>
                            </h5>
                        </div >
                        <form >
                            <div className='row'>
                                <div className='six columns'>
                                    <label htmlFor="Name">
                                        Name
                                    </label>
                                    <input
                                        ref={nameRef}
                                        id="Name"
                                        type="text"
                                        name="Name"
                                        className='u-full-width'
                                        value={card['name']}
                                        onChange={handleInputChange}
                                        required autoFocus
                                    />
                                </div>
                                <div className='six columns'>
                                    <label htmlFor="Email">
                                        Email Address
                                    </label>
                                    <input
                                        ref={emailRef}
                                        id="Email"
                                        type="email"
                                        name="Email"
                                        className='u-full-width'
                                        value={card['email']}
                                        onChange={handleInputChange}
                                        required
                                    />

                                </div>
                            </div>
                            <div className='row'>
                                <div className='twelve columns'>
                                    <label htmlFor="Message">
                                        Message
                                    </label>
                                    <textarea
                                        ref={messageRef}
                                        id="Message"
                                        name="Message"
                                        className='email-message-input u-full-width'
                                        value={card['combinedMessage']}
                                        onChange={handleInputChange}
                                        required
                                    >
                                    </textarea>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='six columns'>
                                    <button
                                        ref={sendButtonRef}
                                        className='button-primary'
                                        onClick={handleSubmit}
                                        type="button"
                                        disabled={!card.expiry && !sendButton}
                                    >
                                        {/* if we are coming from a post transaction, the send button should be enabled */}
                                        <FontAwesomeIcon icon={faEnvelope} />
                                        <span>Send Email</span>
                                    </button>
                                    <span className='spinner'>
                                        <SpinnerInfinity enabled={loading} size={50} thickness={180} speed={100} color="#36ad47" secondaryColor="rgba(0, 0, 0, 0.44)" />
                                    </span>

                                </div>
                                <div className='six columns'>
                                    <ReCAPTCHA
                                        sitekey={sitekey}
                                        onChange={handleRecaptchaChange}
                                        onErrored={disableSending}
                                        onExpired={disableSending}
                                        onSubmit={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='twelve columns'>
                                    <div
                                        ref={captchaCommentRef}
                                        className='comment u-pull-right'
                                    >
                                        Captcha is required to send email
                                    </div>
                                </div>
                            </div>
                        </form >
                    </div >
            }
        </>
    );
}
