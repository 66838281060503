import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLevelDownAlt, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';

function BurgerMenu({ progress, updateProgressInMenu, updateCardInMenu, updateDomlecInMenu, isAuthenticated }) {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    function toggleMenu() {
        setIsOpen(!isOpen);
    }

    function startNewTransaction() {
        toggleMenu();

        updateCardInMenu({
            email: '',
            name: '',
            number: '',
            expiry: '',
            cvv: '',
            amount: '',
            combinedMessage: ''
        });

        updateDomlecInMenu({
            meter: '', account: '', name: '', address: '', domlecTransaction: '',
            processorReferenceNumber: '', approvalCode: '', orderId: '',
            tdateFormatted: '', transactionTime: '', transactionResult: '',
            cardSuccess: false,
            domlecStatus: false,
            domlecMessage: '',
            documentId: ''
        });


        updateProgressInMenu({
            step0: true
        })
    }

    function startContactUs() {
        toggleMenu();

        //we need to check if we are coming from the completed transaction screen
        if (progress.step4) {
            //if so we preserve the data in cards
            updateProgressInMenu({
                contactus: true,
                step4: true
            })
        }
        else {
            //if not we clear out cards
            updateProgressInMenu({
                contactus: true,
            })
        }
    }

    function startTrace() {
        toggleMenu();

        if (!isAuthenticated) { //we are NOT signed in
            updateProgressInMenu({
                login: true
            })
        }
        else { //we are already signed in
            updateProgressInMenu({
                trace: true
            })
        }
    }

    function startChangePassword() {
        toggleMenu();


        updateProgressInMenu({
            password: true
        })

    }

    function startFindTrans() {
        toggleMenu();

        if (!isAuthenticated) { //we are NOT signed in
            updateProgressInMenu({
                login: true
            })
        }
        else { //we are already signed in
            updateProgressInMenu({
                findtrans: true
            })
        }
    }

    function handleSignInOut() {
        toggleMenu();


        updateProgressInMenu({
            login: true
        })
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };


    }, [menuRef]);

    let userLoggedIn = ''
    try {
        const decodedToken = jwtDecode(localStorage.getItem('token'));
        userLoggedIn = decodedToken.username
    } catch (error) {
    }


    return (
        <nav
            ref={menuRef}
            className={`burger-menu ${isOpen ? "burger-menu--open" : ""}`}>
            <button className="burger-menu__button" onClick={toggleMenu}>
            </button>
            <ul className="burger-menu__list">
                <div className='menu-container'>
                    <div className='menu-container-title'>
                        MENU
                        <span className='menu-down-arrow'>
                            <FontAwesomeIcon color='white' icon={faLevelDownAlt} />
                        </span>
                    </div>
                    <div>
                        <Link
                            className='burger-menu__item'
                            onClick={startNewTransaction}
                            to="/">
                            New Transaction
                        </Link>
                    </div>
                    <div>
                        <Link
                            className='burger-menu__item'
                            onClick={startContactUs}
                            to="/contactus"
                        >
                            Contact Us
                        </Link>
                    </div>
                    <div>
                        <Link
                            className='burger-menu__item'
                            onClick={handleSignInOut}
                            to="/login"
                        >
                            {
                                !userLoggedIn ? <span>Sign in</span> : <span>Sign out</span>

                            }
                        </Link>
                    </div>
                    {
                        (userLoggedIn.length > 1) ?
                            <>
                                <div>
                                    <Link
                                        className="burger-menu__item"
                                        onClick={startChangePassword}
                                        to="/password"
                                    >
                                        Change Password
                                        <span className='menu-down-arrow'>
                                            {isAuthenticated ?
                                                <FontAwesomeIcon color='white' icon={faLockOpen} />
                                                :
                                                <FontAwesomeIcon color='white' icon={faLock} />
                                            }
                                        </span>
                                    </Link>
                                </div>
                            </>
                            : ''
                    }
                    {
                        isAuthenticated ?
                            <>
                                <div>
                                    <Link
                                        className="burger-menu__item"
                                        onClick={startTrace}
                                        to="/trace"
                                    >
                                        Trace Transaction
                                        <span className='menu-down-arrow'>
                                            {isAuthenticated ?
                                                <FontAwesomeIcon color='white' icon={faLockOpen} />
                                                :
                                                <FontAwesomeIcon color='white' icon={faLock} />
                                            }
                                        </span>
                                    </Link>
                                </div>
                                <div>
                                    <Link
                                        className="burger-menu__item"
                                        onClick={startFindTrans}
                                        to="/transactions"
                                    >
                                        Find Transactions
                                        <span className='menu-down-arrow'>
                                            {isAuthenticated ?
                                                <FontAwesomeIcon color='white' icon={faLockOpen} />
                                                :
                                                <FontAwesomeIcon color='white' icon={faLock} />
                                            }
                                        </span>
                                    </Link>
                                </div>
                            </>
                            : ''
                    }



                </div>
            </ul>
        </nav >
    );
}

export default BurgerMenu;