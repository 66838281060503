import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import { useRef, useState } from 'react';
import { SpinnerInfinity } from 'spinners-react';
import Tooltip from '@mui/material/Tooltip'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AlertTitle } from '@mui/material';
import { forwardRef } from 'react';


export default function DomlecPayment({ domlecServer, progress, updateProgress, domlec,
    updateDomlec, card, updateCard, makePaymentRef }) {
    const [loading, setLoading] = useState(false);
    const [showError2, setShowError2] = useState(false) //handles alert in case Domlec API server is unvailable
    const [showErrorVendAmount, setShowErrorVendAmount] = useState(false) //handles alert in case Domlec API server is unvailable
    const cardBeingProcessed = useRef(false); //prevents duplicate card processing due to fast double click

    const Alert = forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });


    const closeAlert = (event, reason) => {
        if (reason === 'cleackaway') {
            return;
        }
        setShowError2(false);
        setShowErrorVendAmount(false);
    }

    function handleProcessing() {
        //first disable the make payment button
        makePaymentRef.current.disabled = true;
        setLoading(true);

        let tempCardNumber = card['number'].slice(0, 1) + '*** **** **** ' + card['number'].slice(12)

        const options = {
            method: 'POST',
            url: `${domlecServer}/process`,
            headers: { 'Content-Type': 'application/json' },
            data: {
                name: card['name'],
                email: card['email'],
                number: card['number'],
                expiry: card['expiry'],
                cvv: card['cvv'],
                amount: card['amount'],
                tempCardNumber: tempCardNumber,
                serialnumber: domlec['meter'],
                account: domlec['account'],
                vendoravailable: domlec['vendoravailable']
            }
        };

        if (!cardBeingProcessed.current) { //check to ensure that we are not in the middle of a card tx
            cardBeingProcessed.current = true; //we are going to processing a card
            Axios.request(options).then((response) => {
                if (response.data !== undefined) {

                    if (response.data['transactionResult'] === 'APPROVED') { //card processed ok
                        //we are coming here after a transaction if the contact us button is clicked

                        updateDomlec({
                            ...domlec,
                            // approvalCode: response.data['approvalCode'],
                            // processorReferenceNumber: response.data['processorReferenceNumber'],
                            // tdateFormatted: response.data['tdateFormatted'],
                            // transactionTime: response.data['transactionTime'],
                            transactionResult: response.data['transactionResult'],
                            // orderId: response.data['orderId'],
                            // errorMessage: response.data['errorMessage'],
                            cardSuccess: response.data['cardSuccess'],
                            // domlecTransaction: response.data['domlecTransaction'],
                            // domlecSuccess: response.data['domlecSuccess'],
                            // domlecMessage: response.data['domlecMessage'],
                            // domlecStatus: response.data['domlecStatus'],
                            // status: response.data['status'],
                            documentId: response.data['documentId'],
                            tempCardNumber: tempCardNumber,
                            amount: parseFloat(card['amount']).toFixed(2)
                        })
                    }
                    else {
                        //the card was DECLINED...so update the main prop and reload to present results to user
                        updateDomlec({
                            ...domlec,
                            // approvalCode: response.data['approvalCode'],
                            // processorReferenceNumber: response.data['processorReferenceNumber'],
                            // tdateFormatted: response.data['tdateFormatted'],
                            // transactionTime: response.data['transactionTime'],
                            transactionResult: response.data['transactionResult'],
                            // orderId: response.data['orderId'],
                            // errorMessage: response.data['errorMessage'],
                            cardSuccess: false,
                            documentId: response.data['documentId'],
                            tempCardNumber: tempCardNumber,
                            amount: parseFloat(card['amount']).toFixed(2),
                            domlecTransaction: 'NO VEND'
                        })
                    }

                    // let domlecTx = "NO VEND"
                    // if (response.data['transactionResult'] === 'APPROVED') {
                    //     domlecTx = response.data['domlecTransaction']
                    // }

                    let combinedMessage =
                        `Transaction details:

Your reference number ${response.data['documentId']}
Card processing result ${response.data['transactionResult']}
Card used ${tempCardNumber}

Meter number ${domlec['meter']}
Account number ${domlec['account']} 
Amount $${parseFloat(card['amount']).toFixed(2)}`

                    // let combinedMessage =
                    // `Transaction details:

                    // Your reference number ${response.data['documentId']}
                    // Card processing result ${response.data['transactionResult']}
                    // Card processor reference ${response.data['processorReferenceNumber']} 
                    // Card approval code ${response.data['approvalCode']} 
                    // Card used ${tempCardNumber}
                    // Transaction date ${response.data['tdateFormatted']}

                    // Domlec transaction reference ${domlecTx}
                    // Meter number ${domlec['meter']}
                    // Account number ${domlec['account']} 
                    // Amount $${parseFloat(card['amount']).toFixed(2)}`

                    updateCard({ ...card, combinedMessage: combinedMessage })
                }
                else {
                    alert(`Couldn't process Card transaction`)
                }
                setLoading(false);
                updateProgress({ step4: true })
            }).catch((error) => {
                // console.log(`Error code: ${error.response.status}`)
                makePaymentRef.current.disabled = false;
                setLoading(false);
                if (error.response.status === 403) {
                    //vend amount exceeds vendor available balance....alert user
                    setShowErrorVendAmount(true);
                }
                else {
                    //a connection issue with FiServ API server
                    setShowError2(true);
                }
            }).finally(() => {
                cardBeingProcessed.current = false; //set back to false so we can handle a new transaction
            });
        }
        // else {
        // console.log('- Duplicate card processing attempt detected')
        // }

    }

    return (
        <div className='row'>
            <div className='twelve columns'>
                {
                    progress.step3 ?
                        <div className='validate-section'>


                            <button
                                ref={makePaymentRef}
                                className="button-primary"
                                onClick={handleProcessing}>
                                <FontAwesomeIcon color='white' icon={faCreditCard} />
                                <span>Make Payment</span>
                            </button>
                            {/*<Spinner visible={loading} />*/}
                            <span className='spinner'>
                                <SpinnerInfinity enabled={loading} size={50} thickness={180} speed={100} color="#36ad47" secondaryColor="rgba(0, 0, 0, 0.44)" />
                            </span>
                        </div>
                        :
                        <div className='validate-section'>
                            <Tooltip
                                title='Please ensure that ALL the fields contain valid data'
                                className='paymentButtonTooltip'
                                arrow
                            >
                                <span>
                                    <button
                                        disabled
                                        style={{ pointerEvents: 'none' }}
                                        ref={makePaymentRef}
                                        className="button-primary"
                                    // onClick={handleProcessing}
                                    >
                                        <FontAwesomeIcon color='white' icon={faCreditCard} />
                                        <span>Make Payment</span>
                                    </button>
                                </span>
                            </Tooltip>
                            {/*<Spinner visible={loading} />*/}
                            <span className='spinner'>
                                <SpinnerInfinity enabled={loading} size={50} thickness={180} speed={100} color="#36ad47" secondaryColor="rgba(0, 0, 0, 0.44)" />
                            </span>
                        </div>
                }
            </div>
            <Snackbar
                open={showError2}
                autoHideDuration={4000}
                onClose={closeAlert}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={closeAlert} severity="error">
                    <AlertTitle>Connection Error</AlertTitle>
                    Unable to connect to the Card Processing server
                </Alert>
            </Snackbar>
            <Snackbar
                open={showErrorVendAmount}
                autoHideDuration={4000}
                onClose={closeAlert}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={closeAlert} severity="error">
                    <AlertTitle>Unable to process transaction</AlertTitle>
                    Amount exceeds available vendor balance.
                </Alert>
            </Snackbar>


        </div >


    )
}