import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import { useState, useRef } from 'react';
import { SpinnerInfinity } from 'spinners-react';
import Tooltip from '@mui/material/Tooltip'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AlertTitle } from '@mui/material';
import { forwardRef } from 'react';

export default function Domlec({ domlecServer, progress, updateProgress,
    domlec, updateDomlec, validateButtonRef, updateCard }) {
    const [loading, setLoading] = useState(false); //determines when to show the spinning/loading wheel
    const meterInputRef = useRef(null);
    const accountInputRef = useRef(null);
    const [lookupResult, setLookupResult] = useState('')
    const [showError, setShowError] = useState(false) //handles alert in case Domlec API server is unvailable


    const Alert = forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });


    const closeAlert = (event, reason) => {
        if (reason === 'cleackaway') {
            return;
        }
        setShowError(false);
    }

    function handleEnterKeyPress(event) {
        if (event.key === 'Enter' && progress.step1) {
            validateButtonRef.current.focus(); //set focus on Validate button (which will be disabled)
            handleClickP();
        }
    }

    function handleInputChange(event) {

        setLookupResult('')

        if (event.target.name === 'meter') {
            //only allow uppercase letters
            updateDomlec({ ...domlec, meter: event.target.value.toUpperCase() })
        }
        else {
            //only allow numbers to be entered
            updateDomlec({ ...domlec, account: event.target.value.replace(/[^0-9]/g, "") })
        }


        if (meterInputRef.current.value.length > 3 && accountInputRef.current.value.length > 0) {
            //if (domlec['meter'].length > 3 && domlec['account'].length > 1) {
            //reenable the validate button
            validateButtonRef.current.disabled = false;

            //step1 means validate button is available for use
            updateProgress({
                step0: true,
                step1: true
            })
        }
        else {
            //disable the validate button
            validateButtonRef.current.disabled = true;

            //disable validate button
            updateProgress({
                step0: true,
                step1: false
            })
        }
    }

    function handleClickP() {

        //disable the validate button while we are searching....i.e. don't allow repeated pressing of the button
        validateButtonRef.current.disabled = true;
        updateCard({
            email: '',
            name: '',
            number: '',
            expiry: '',
            cvv: '',
            amount: '',
            combinedMessage: ''
        });
        updateProgress({
            step0: true,
            step1: false,
            step2: false
        })

        setLoading(true);

        const options = {
            method: 'POST',
            url: `${domlecServer}/search`,
            headers: { 'Content-Type': 'application/json' },
            data: {
                serialnumber: meterInputRef.current.value,
                account: accountInputRef.current.value
            }
        };

        Axios.request(options).then((response) => {
            if (response.data !== undefined && response.data['status'] === 'OK' &&
                response.data['serialnumber'] === meterInputRef.current.value &&
                response.data['account'] === accountInputRef.current.value) {

                updateDomlec({
                    meter: response.data['serialnumber'],
                    account: response.data['account'],
                    address: response.data['address'],
                    name: response.data['name'],
                    minvend: response.data['minvend'],
                    maxvend: response.data['maxvend'],
                    vendoravailable: response.data['vendoravailable']
                })
                updateProgress({
                    step0: true,
                    step1: true,
                    step2: true
                })
                setLoading(false);
            }
            else {
                setLoading(false);
                updateDomlec({
                    meter: '',
                    account: '',
                    address: '',
                    name: '',
                    minvend: response.data['minvend'],
                    maxvend: response.data['maxvend'],
                })
                updateProgress({
                    step0: true,
                    step1: false
                })
                setLookupResult('Customer not found!')
            }
            updateCard({
                email: '',
                name: '',
                number: '',
                expiry: '',
                cvv: '',
                amount: '',
                combinedMessage: ''
            });
            setLoading(false);
        }).catch((error) => {
            setLoading(false);

            updateDomlec({
                ...domlec,
                meter: '',
                account: ''
            })
            setShowError(true);
        });

        validateButtonRef.current.disabled = false; //renable
    }

    function showCustomerDetails() {

        return (
            <>
                <table className='account-retrieved-table'>
                    <tbody>
                        <tr>
                            <th colSpan={2}>
                                <span className='account-retrieved-title'>ACCOUNT INFORMATION RETRIEVED:</span>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <span className='account-retrieved-fields'>Name:</span>
                            </td>
                            <td>
                                {domlec['name']}
                            </td>
                        </tr>
                        {
                            /*
                        <tr>
                            <td>
                                <span className='account-retrieved-fields'>Address:</span>
                            </td>
                            <td>
                                {domlec['address']}
                            </td>
                        </tr>
    
                            */
                        }
                        <tr>
                            <td>
                                <span className='account-retrieved-fields'>Meter #:</span>
                            </td>
                            <td>
                                {domlec['meter']}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span className='account-retrieved-fields'>Account #:</span>
                            </td>
                            <td>
                                {domlec['account']}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>
        )
    }

    return (
        <>
            <div
                onKeyDown={handleEnterKeyPress}
            >
                <div className='row'>
                    <div className='twelve columns'>
                        <h4>ACCOUNT DETAILS</h4>
                        <h5>Please enter a valid Meter and Account number in the fields below</h5>
                    </div>
                </div>

                <div className="row">
                    <div className="six columns">
                        <label htmlFor='meter'>Meter Number <span></span>
                            {
                                domlec['meter'].length > 3 ?
                                    <span className='greenTick'>
                                        <FontAwesomeIcon color='var(--green-color)' icon={faCheck} />
                                    </span>
                                    :
                                    <>
                                    </>
                            }
                        </label>
                        <input
                            className='u-full-width'
                            ref={meterInputRef}
                            name="meter"
                            type='text'
                            value={domlec['meter']}
                            onInput={handleInputChange}
                            text="text"
                            placeholder="SRD12345"
                            autoFocus required
                        />
                    </div>
                    <div className="six columns">
                        <label htmlFor='account'>Account Number <span></span>
                            {
                                domlec['account'].length > 0 ?
                                    <span className='greenTick'>
                                        <FontAwesomeIcon color='var(--green-color)' icon={faCheck} />
                                    </span>
                                    :
                                    <>
                                    </>
                            }
                        </label>
                        <input
                            className='u-full-width'
                            ref={accountInputRef}
                            type='text'
                            name="account"
                            value={domlec['account']}
                            onInput={handleInputChange}
                            text="number"
                            placeholder="1234"
                            required
                        />

                    </div>
                </div>
                <div className='row'>
                    <div className='twelve columns'>
                        {progress.step1 ?
                            <button ref={validateButtonRef} className="button-primary" onClick={handleClickP}>
                                <FontAwesomeIcon color='white' icon={faCheck} />
                                <span>Validate</span>
                            </button>
                            :
                            <Tooltip
                                title='Enter a valid Meter Number AND Account Number'
                                arrow
                            >
                                <span>

                                    <button
                                        style={{ pointerEvents: 'none' }}
                                        disabled
                                        ref={validateButtonRef}
                                        className="button-primary"
                                        onClick={handleClickP}
                                    >
                                        <FontAwesomeIcon color='white' icon={faCheck} />
                                        <span>Validate</span>
                                    </button>
                                </span>
                            </Tooltip>
                        }
                        {/*<Spinner className='u-cf' visible={loading} />*/}
                        {/*<SpinnerCircular enabled={loading} size={50} thickness={100} speed={100} color="#36ad47" secondaryColor="rgba(0, 0, 0, 0.44)" />*/}
                        <span className='spinner'>
                            <SpinnerInfinity enabled={loading} size={50} thickness={180} speed={100} color="#36ad47" secondaryColor="rgba(0, 0, 0, 0.44)" />
                        </span>
                        <span className='lookup-not-found'>
                            {lookupResult}
                        </span>
                    </div>
                </div>
                {
                    progress.step2 ?
                        <div className='row'>
                            <div className='twelve columns'>
                                <h5>{(domlec['name'].length > 0) ? showCustomerDetails() : ' '}</h5>
                            </div>
                        </div>
                        : ' '
                }
            </div>
            <Snackbar
                open={showError}
                autoHideDuration={4000}
                onClose={closeAlert}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={closeAlert} severity="error">
                    <AlertTitle>Connection Error</AlertTitle>
                    Unable to connect to the Domlec API server
                </Alert>
            </Snackbar>

        </>
    )
}