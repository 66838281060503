import React from 'react';
import BurgerMenu from './BurgerMenu';
import logo from './images/juice_header_logo.png';


function Header({ progress, updateProgressFromParent, updateCardFromParent,
    domlec, updateDomlecFromParent, rkey, updateRkey, isAuthenticated, updateIsAuthenticated }) {

    function handleOnClick() {
        window.location.reload(); //reload the page if the logo is clicked
    }

    return (
        <div className='header'>
            <div className='row'>
                <div className='ten columns'>
                    <div className='logo-div'>
                        <img
                            className='logo'
                            onClick={handleOnClick}
                            src={logo}
                            alt="Domlec Juice logo"
                        />
                    </div>
                </div>
                <div className='two columns'>
                    <BurgerMenu
                        progress={progress}
                        updateProgressInMenu={updateProgressFromParent}
                        updateCardInMenu={updateCardFromParent}
                        updateDomlecInMenu={updateDomlecFromParent}
                        isAuthenticated={isAuthenticated}
                    />
                </div>
            </div>
        </div>

    );
};

export default Header;