import { useState, useRef } from "react"
import Axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn, faSignOut } from '@fortawesome/free-solid-svg-icons';
import jwtDecode from "jwt-decode";

export default function Login({ domlecServer, isAuthenticated, updateProgressFromParent,
    updateIsAuthenticated }) {
    const usernameRef = useRef(null);
    const passwordRef = useRef(null);
    const failedLoginMessageRef = useRef(null);
    const showPasswordRef = useRef(null);

    const navigate = useNavigate();

    function showPassword() {
        if (showPasswordRef.current.checked) {
            passwordRef.current.type = "text";
        }
        else {
            passwordRef.current.type = "password";
        }
    }

    function signOut() {
        localStorage.removeItem('token'); //delete the token from local storage
        updateIsAuthenticated(false)
        setSignedIn(false)
    }

    function handleEnterKeyPress(event) {
        if (event.key === 'Enter') {
            validateLogin()
        }
    }

    function validateLogin() {
        //setLoading(true);
        const options = {
            method: 'POST',
            url: `${domlecServer}/login`,
            headers: { 'Content-Type': 'application/json' },
            data: {
                username: usernameRef.current.value,
                password: passwordRef.current.value
            }
        };

        Axios.request(options).then((response) => {
            if (response.status === 200) { //valid login
                //store token
                localStorage.setItem('token', response.data.token);
                const decodedToken = jwtDecode(response.data.token)

                if (!decodedToken.changeRequired) {
                    updateIsAuthenticated(true)
                }
                else if (decodedToken.changeRequired) {
                    updateIsAuthenticated(false)

                    updateProgressFromParent({
                        password: true
                    })

                    navigate('/password')
                }
                setSignedIn(true)
            }
            else {
                failedLoginMessageRef.current.innerHTML = 'Invalid username or password'
                setSignedIn(false)
            }
        }).catch((AxiosError) => {
            setSignedIn(false)

            switch (AxiosError.response.status) {
                case 400:
                    failedLoginMessageRef.current.innerHTML = 'Account locked'
                    break;
                case 401:
                    failedLoginMessageRef.current.innerHTML = 'Invalid username or password'
                    break;
                default:
                    console.log(AxiosError)
                    break;
            }
        }).catch((err) => {
            console.error(err)
            alert('Unable to access database.  Contact Domlec IT Support')
            setSignedIn(false)
        })
    }

    let userLoggedIn = ''

    try {
        const decodedToken = jwtDecode(localStorage.getItem('token'));
        userLoggedIn = decodedToken.username
    } catch (error) {
    }

    const [signedIn, setSignedIn] = useState(userLoggedIn.length > 0)

    return (
        <>
            <div
                onKeyDown={handleEnterKeyPress}
            >
                {
                    !signedIn ? (
                        <>
                            <div className="row">
                                <div className="twelve columns">
                                    <h2>Sign in</h2>

                                </div>
                            </div>

                            <div className="row">
                                <div className="six columns">
                                    <label htmlFor="username">Username</label>
                                    <input
                                        ref={usernameRef}
                                        id="username"
                                        name="username"
                                        type="text"
                                        autoComplete="username" required autoFocus />
                                </div>
                                <div className="six columns">
                                    <label htmlFor="current-password">Password</label>
                                    <input
                                        ref={passwordRef}
                                        id="current-password"
                                        name="current-password"
                                        type="password"
                                        autoComplete="current-password" required />
                                </div>
                            </div>

                            <div className="row">
                                <div className="twelve columns">
                                    <label>
                                        <input
                                            onClick={showPassword}
                                            ref={showPasswordRef}
                                            id="showPwd"
                                            type="checkbox" /> Show Password
                                    </label>
                                </div>
                                <div className="twelve columns">
                                    <button
                                        onClick={validateLogin}
                                        className="button-primary login-button"
                                        type="text">
                                        <FontAwesomeIcon color='white' icon={faSignIn} />
                                        <span>Sign In</span>
                                    </button>
                                    <div>
                                        <span
                                            className="login-failed"
                                            ref={failedLoginMessageRef}>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                        : (
                            <>
                                <div className="row">
                                    <div className="twelve columns">
                                        <h2>Sign out</h2>
                                    </div>
                                    <div className='twelve-columns'>
                                        <h5 className='user-logged-in'>
                                            {
                                                userLoggedIn ?
                                                    <>{userLoggedIn} logged in</>
                                                    : ''

                                            }
                                        </h5>
                                    </div>

                                </div>


                                <div className="row">
                                    <div className="twelve columns">
                                        <button
                                            onClick={signOut}
                                            className="button-primary logout-button"
                                            type="text">
                                            <FontAwesomeIcon color='white' icon={faSignOut} />
                                            <span>Sign Out</span>
                                        </button>
                                    </div>
                                </div>

                            </>
                        )
                }

            </div>
        </>
    )
}