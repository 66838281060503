import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import jwtDecode from 'jwt-decode';

export default function Trail({ progress }) {
    /*
    This component will display the breadcrumb trail beneath the header
    */

    let currentRoute = window.location.pathname
    let decodedToken = false;

    try {
        decodedToken = jwtDecode(localStorage.getItem('token'));
    } catch (error) {

    }

    switch (currentRoute) {
        case '/':
            if (progress.step4) {
                currentRoute = 'Transaction Summary'
            }
            else {
                currentRoute = 'Meter Details'
            }
            break;
        case '/login':
            currentRoute = 'Login'
            break;
        case '/password':
            currentRoute = 'Update Password'
            break;
        case '/contactus':
            if (progress.step4) {
                currentRoute = 'Contact Us from Transaction Summary'
            }
            else {
                currentRoute = 'Contact Us'
            }
            break;
        case '/trace':
            if (decodedToken.changeRequired) {
                currentRoute = 'Update Password'
            }
            else {
                currentRoute = 'Trace Transaction'
            }
            break;
        case '/transactions':
            if (decodedToken.changeRequired) {
                currentRoute = 'Update Password'
            }
            else {
                currentRoute = 'Transaction Report'
            }
            break;
        default:
            break;
    }

    return (
        <div className='row trail-border'>
            <span className='trail'>
                <span className='trail-text'>{currentRoute}</span>
            </span>
            {
                progress.step2 ?
                    <>
                        <span>
                            <FontAwesomeIcon color='#20c997' icon={faCircleCheck} />
                        </span>
                        <span className='trail'>Card Details</span>
                    </>
                    : ' '
            }

            {
                progress.step3 ?
                    <span>
                        <FontAwesomeIcon color='#20c997' icon={faCircleCheck} />
                    </span>
                    : ' '
            }
        </div>

    );
}