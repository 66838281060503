import { Navigate, Outlet } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

export default function ProtectedRoute({ element, ...rest }) {

    //try to read the token from localStorage (i.e. we are logged in)

    let changeRequired = true
    try {
        const decodedToken = jwtDecode(localStorage.getItem('token'));
        changeRequired = decodedToken.changeRequired
    } catch (error) {
    }

    //if the token is still valid, render the protected content (Outlet), other redirect to login
    return (
        !changeRequired ? <Outlet /> : <Navigate to={'/login'} />
    )

}