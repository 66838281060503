import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedoAlt, faCircleCheck, faWindowClose, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export default function TransactionComplete({ domlec, updateCard, updateProgress, updateDomlec }) {

    function handleClick() {
        /* reset the card, domlec and progress state effectively resetting to default */
        updateCard({
            email: '',
            name: '',
            number: '',
            expiry: '',
            cvv: '',
            amount: '',
            combinedMessage: ''
        });

        updateDomlec({
            meter: '', account: '', name: '', address: '', domlecTransaction: '',
            processorReferenceNumber: '', approvalCode: '', orderId: '',
            tdateFormatted: '', transactionTime: '', transactionResult: '',
            cardSuccess: false,
            domlecStatus: false,
            domlecMessage: '',
            documentId: ''
        });

        updateProgress({ step0: true });

    }

    let domlecError = domlec['domlecMessage'];

    if (domlec['domlecMessage'] === 'Duplicate Vend Detected, Resubmit in 2 minutes if valid') {
        domlecError = 'Duplicate Vend Detected'
    }
    else {
        domlecError = 'NO VEND'
    }

    return (
        <>
            <div className='row'>
                <div className='twelve columns'>
                    {
                        domlec.cardSuccess ?
                            <>
                                {

                                    // domlec.domlecSuccess ?
                                    //we are no longer waiting for the domlec transaction...so success is based on the card processing result
                                    <h4>TRANSACTION COMPLETE<span><FontAwesomeIcon color='green' icon={faCircleCheck} /></span></h4>
                                    // :
                                    // <h4>TRANSACTION INCOMPLETE<span><FontAwesomeIcon color='orange' icon={faExclamationCircle} /></span></h4>

                                }
                                <h5><span style={{ fontWeight: 600 }}>Your reference number</span> <div> {domlec['documentId']} </div></h5>
                                <h5><span style={{ fontWeight: 600 }}>Card processing result</span> <div> {domlec['transactionResult']} </div> </h5>
                                <h5><span style={{ fontWeight: 600 }}>Card number</span> <div> {domlec['tempCardNumber']} </div> </h5>
                                <h5><span style={{ fontWeight: 600 }}>Transaction amount </span> <div>${domlec['amount']} </div> </h5>
                                {
                                    /*
                                    <h5><span style={{ fontWeight: 600 }}>Card processor reference</span> <div> {domlec['processorReferenceNumber']} </div> </h5>
                                    <h5><span style={{ fontWeight: 600 }}>Card approval code </span><div> {domlec['approvalCode']}</div> </h5>
                                    <h5><span style={{ fontWeight: 600 }}>Domlec transaction reference</span>
                                        {domlec.domlecSuccess && <div>{domlec['domlecTransaction']}</div>}
                                        {!domlec.domlecSuccess && <div>{domlecError}</div>}
                                        {!domlec.domlecSuccess && <div>Contact Domlec's Customer Support </div>}
                                    </h5>
                                    */
                                }
                                <h5><span style={{ fontWeight: 600 }}>Domlec meter number</span><div> {domlec['meter']}</div> </h5>
                                <h5><span style={{ fontWeight: 600 }}>Domlec account number</span><div> {domlec['account']}</div> </h5>
                            </>
                            :
                            <>
                                <h4>CARD PROCESSING FAILED<span><FontAwesomeIcon color='red' icon={faWindowClose} /></span></h4>
                                <h5><span style={{ fontWeight: 600 }}>Your reference number </span> <div>{domlec['documentId']} </div> </h5>
                                <h5><span style={{ fontWeight: 600 }}>Card processing result </span><div> {domlec['transactionResult']} </div> </h5>
                                <h5><span style={{ fontWeight: 600 }}>Card number</span> <div> {domlec['tempCardNumber']} </div> </h5>
                                <h5><span style={{ fontWeight: 600 }}>Transaction amount </span> <div>${domlec['amount']} </div> </h5>
                                {
                                    /*
                                    <h5><span style={{ fontWeight: 600 }}>Card processor reference </span> <div>{domlec['processorReferenceNumber']} </div> </h5>
                                    <h5><span style={{ fontWeight: 600 }}>Card processor code </span> <div>{domlec['approvalCode']} </div> </h5>
                                    <h5><span style={{ fontWeight: 600 }}>Domlec transaction reference</span>
                                        {domlec.domlecSuccess && <div>{domlec['domlecTransaction']}</div>}
                                        {!domlec.domlecSuccess && <div>{domlecError}</div>}
                                    </h5>
                                    */
                                }
                                <h5><span style={{ fontWeight: 600 }}>Domlec meter number</span><div> {domlec['meter']}</div> </h5>
                                <h5><span style={{ fontWeight: 600 }}>Domlec account number</span><div> {domlec['account']}</div> </h5>

                            </>
                    }
                </div>
            </div>

            <div className='row'>
                <div className='twelve columns'>
                    <button
                        className="button-primary new-transaction"
                        onClick={handleClick}>
                        <FontAwesomeIcon color='white' icon={faRedoAlt} />
                        <span>New transaction</span>
                    </button>

                </div>
            </div >
        </>
    )
}