import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import Axios, { AxiosError } from 'axios';
import { useState, useRef } from 'react';
import jwtDecode from 'jwt-decode';


export default function ChangePassword({ domlecServer, updateIsAuthenticated }) {
    const [loading, setLoading] = useState(false); //determines when to show the spinning/loading wheel
    const [changePasswordAvailable, setChangePasswordAvailable] = useState(false)
    const currentPasswordRef = useRef(null)
    const newPasswordRef = useRef(null)
    const confirmPasswordRef = useRef(null)
    const messageRef = useRef(null)
    const showPasswordRef = useRef(null)
    const [resultMessage, setResultMessage] = useState('')

    function handleEnterKeyPress(event) {
        if (event.key === 'Enter' && changePasswordAvailable) {
            handleClickP()
        }
    }

    function verifyPasswordComplexity(password, username) {
        //helper function to verify the password complexity
        const minLength = 8;
        const hasNumber = /\d/;
        const hasLowercase = /[a-z]/;
        const hasUppercase = /[A-Z]/;
        const hasSymbol = /[!@#$%^&*()]/

        if (password.length < minLength) {
            return false;
        }
        // console.log('length is ok ')

        if (password.includes(username)) {
            return false;
        }
        // console.log('does not contain username ')

        if (!hasNumber.test(password)) {
            return false;
        }
        // console.log('has number ')

        if (!hasLowercase.test(password)) {
            return false;
        }
        // console.log('has lowercase ')

        if (!hasUppercase.test(password)) {
            return false;
        }
        // console.log('has uppercase ')

        if (!hasSymbol.test(password)) {
            return false;
        }
        // console.log('has symbol ')

        return true;
    }

    function showPassword() {
        if (showPasswordRef.current.checked) {
            currentPasswordRef.current.type = "text";
            newPasswordRef.current.type = "text";
            confirmPasswordRef.current.type = "text";
        }
        else {
            currentPasswordRef.current.type = "password";
            newPasswordRef.current.type = "password";
            confirmPasswordRef.current.type = "password";
        }
    }




    function handleOnChange(event) {
        setResultMessage('')
    }

    function handleInputChange(event) {

        //messageRef.current.innerHTML = ''

        // console.log('checking input')
        if (newPasswordRef.current.value === confirmPasswordRef.current.value &&
            newPasswordRef.current.value !== currentPasswordRef.current.value &&
            currentPasswordRef.current.value.length > 7 &&
            verifyPasswordComplexity(newPasswordRef.current.value, userLoggedIn) &&
            verifyPasswordComplexity(confirmPasswordRef.current.value, userLoggedIn)) {
            setChangePasswordAvailable(true)
        }
        else {
            // console.log('disabling button ')
            setChangePasswordAvailable(false)
        }
    }

    function handleClickP() {
        const token = localStorage.getItem('token');

        const options = {
            method: 'POST',
            url: `${domlecServer}/password`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: {
                username: userLoggedIn,
                currentPassword: currentPasswordRef.current.value,
                newPassword: newPasswordRef.current.value
            }
        };

        Axios.request(options).then((response) => {
            if (response.status === 200) { //succeeded with password change
                localStorage.setItem('token', response.data.token);
                decodedToken = jwtDecode(response.data.token)
                console.log('updating token')
                //messageRef.current.innerHTML = 'Password updated'

                //clear the input fields after a successful save
                currentPasswordRef.current.value = ''
                newPasswordRef.current.value = ''
                confirmPasswordRef.current.value = ''

                setResultMessage('Password updated')
                setChangePasswordAvailable(false)
                updateIsAuthenticated(true)

            }
            else {
                //messageRef.current.innerHTML = response.data.message
                setResultMessage(`result: ${response.data.message}`)
            }
        }).catch((AxiosError) => {
            switch (AxiosError.response.status) {
                case 400:
                    //messageRef.current.innerHTML = 'Previous 5 used passwords not allowed'
                    setResultMessage('Previous 5 used passwords not allowed')
                    break;
                case 401:
                    //messageRef.current.innerHTML = 'Invalid password'
                    setResultMessage('Invalid password')
                    break;
                default:
                    console.log(AxiosError)
                    break;
            }
        }).catch((err) => {
            console.error(err)
            alert('Unable to access database.  Contact Domlec IT Support')
        })
    }

    let userLoggedIn = ''
    let changeRequired = false
    let decodedToken
    try {
        decodedToken = jwtDecode(localStorage.getItem('token'));
        userLoggedIn = decodedToken.username
        changeRequired = decodedToken.changeRequired
    } catch (error) {

    }

    return (
        <>
            <div
                className='overflow-transactions'
                onKeyDown={handleEnterKeyPress}
            >
                <div className="row">
                    <div className="twelve columns">
                        <h2>Change Password</h2>
                    </div>
                    <div className='twelve-columns'>
                        <h5 className='user-logged-in'>
                            {
                                userLoggedIn ?
                                    <>
                                        <div>{userLoggedIn} logged in</div>
                                        {
                                            (changeRequired) ?
                                                <div>(password change required)</div>
                                                : ''
                                        }

                                    </>
                                    : ''

                            }

                        </h5>
                    </div>
                </div>
                <div className="row">
                    <div className="twelve columns">
                        <label htmlFor="current-password">Current Password</label>
                        <input
                            className='u-full-width'
                            ref={currentPasswordRef}
                            onInput={handleInputChange}
                            onChange={handleOnChange}
                            name="current-password"
                            id="current-password"
                            type="password"
                            autoComplete="current-password" required autoFocus />

                    </div>
                </div>
                <div className="row">
                    <div className="twelve columns">
                        <label htmlFor="new-password">New Password</label>
                        <input
                            className='u-full-width'
                            ref={newPasswordRef}
                            onInput={handleInputChange}
                            onChange={handleOnChange}
                            name="new-password"
                            id="new-password"
                            type="password"
                            autoComplete="new-password" required />
                    </div>
                </div>
                <div className="row">
                    <div className="twelve columns">
                        <label htmlFor="confirm-password">Confirm Password</label>
                        <input
                            onInput={handleInputChange}
                            onChange={handleOnChange}
                            ref={confirmPasswordRef}
                            className='u-full-width'
                            name="confirm-password"
                            id="confirm-password"
                            type="password"
                            autoComplete="confirm-password" required />
                    </div>
                    <div className="twelve columns">
                        <label>
                            <input
                                onClick={showPassword}
                                ref={showPasswordRef}
                                id="showPwd"
                                type="checkbox" /> Show Password
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="twelve columns">
                        <h5
                            className='password-message'
                            ref={messageRef}
                        >
                            {resultMessage}
                        </h5>
                    </div>
                </div>
                <div className="row">
                    <div className="twelve columns">
                        Password requirements:
                        <li>at least 8 characters long</li>
                        <li>cannot include your username</li>
                        <li>must container a number</li>
                        <li>must container a lower case leter</li>
                        <li>must container an upper case leter</li>
                        <li>must container a symbol</li>
                    </div>
                </div>

                <div className="row">
                    <div className="twelve columns">
                        {
                            changePasswordAvailable
                                ? (<button
                                    onClick={handleClickP}
                                    className="button-primary logout-button"
                                    type="text">
                                    <FontAwesomeIcon color='white' icon={faAddressBook} />
                                    <span>Change Password</span>
                                </button>
                                )
                                : (<button disabled
                                    onClick={handleClickP}
                                    className="button-primary logout-button"
                                    type="text">
                                    <FontAwesomeIcon color='white' icon={faAddressBook} />
                                    <span>Change Password</span>
                                </button>
                                )
                        }
                    </div>
                </div>
            </div >
        </>
    )
}