import { useState } from 'react';

import './css/App.css';
import './css/normalize.css';
import './css/skeleton.css';

import Header from './Header';
import Trail from './Trail';
import Footer from './Footer'
import Home from './Home';
import ContactUs from './ContactUs';
import Trace from './Trace';
import FindTrans from './FindTrans';
import Login from './Login';
import ProtectedRoute from './ProtectedRoute';
import ChangePassword from './ChangePassword';
import jwtDecode from 'jwt-decode';

import { Route, Routes, Navigate } from 'react-router-dom';


function App() {
  //this state tracks the progress
  const [progress, setProgress] = useState({
    step0: true,
  });

  const [card, setCard] = useState({
    email: '',
    name: '',
    number: '',
    expiry: '',
    cvv: '',
    amount: '',
    combinedMessage: ''

  })

  const [domlec, setDomlec] = useState({
    meter: '', account: '', name: '', address: '', domlecTransaction: '',
    processorReferenceNumber: '', approvalCode: '', orderId: '',
    tdateFormatted: '', transactionTime: '', transactionResult: '',
    cardSuccess: false,
    domlecStatus: false,
    domlecMessage: '',
    documentId: ''
  })

  let checkAuthStatus = false
  try {
    const decodedToken = jwtDecode(localStorage.getItem('token')) //get the value of the changeRequired in the token
    checkAuthStatus = !decodedToken.changeRequired //set authenticated status to opposite of change required
  } catch (error) {

  }

  const [isAuthenticated, setIsAuthenticated] = useState(checkAuthStatus)

  const domlecServer = ''; //this works because of proxy": "http://127.0.0.1:3001" in package.json

  function updateProgressInApp(newProgress) {
    setProgress(newProgress)
  }

  function updateCardInApp(newCard) {
    setCard(newCard)
  }

  function updateDomlecInApp(newDomlec) {
    setDomlec(newDomlec)
  }

  function updateIsAuthenticated(authenticationState) {
    setIsAuthenticated(authenticationState)
  }

  /**** for production */
  //to build:
  //npm run build

  //to listen:
  //serve -s build -p 8888
  //better to serve with the express js server

  /***** for development */
  //npm start

  return (
    <>
      <div className="container">
        <Header
          progress={progress}
          updateProgressFromParent={updateProgressInApp}
          updateCardFromParent={updateCardInApp}
          updateDomlecFromParent={updateDomlecInApp}
          isAuthenticated={isAuthenticated}
          updateIsAuthenticated={updateIsAuthenticated}
        />
        <Trail
          progress={progress}
          updateProgress={updateProgressInApp} />

        <Routes>
          <Route
            path='/'
            element={
              <Home
                progress={progress}
                updateProgressFromParent={updateProgressInApp}
                domlecServer={domlecServer}
                card={card}
                updateCardFromParent={updateCardInApp}
                domlec={domlec}
                updateDomlecFromParent={updateDomlecInApp}
              />
            } exact
          />
          <Route
            path='/contactus'
            element={
              <ContactUs
                updateProgressFromParent={updateProgressInApp}
                domlecServer={domlecServer}
                card={card}
                updateCardFromParent={updateCardInApp}
                updateDomlecFromParent={updateDomlecInApp}
              />
            }
          />
          <Route
            element={<ProtectedRoute />}>
            <Route
              path='/trace'
              element={<Trace domlecServer={domlecServer} updateIsAuthenticated={updateIsAuthenticated} />}
            />
            <Route
              path='/transactions'
              element={
                <FindTrans domlecServer={domlecServer} updateIsAuthenticated={updateIsAuthenticated} />}
            />

          </Route>
          <Route
            path='/password'
            element={
              <ChangePassword
                domlecServer={domlecServer}
                updateIsAuthenticated={updateIsAuthenticated}
              />}
          />

          <Route
            path='/login'
            element={
              <Login
                domlecServer={domlecServer}
                updateIsAuthenticated={updateIsAuthenticated}
                updateProgressFromParent={updateProgressInApp}
              />
            }
            exact
          />
          <Route
            path='*'
            element={<Navigate to="/" replace />}
          />
        </Routes>

        <Footer />
      </div>
    </>
  );
}

export default App;
