import { useState, useRef } from "react"
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons';
import { SpinnerInfinity } from 'spinners-react';
import Axios from 'axios';
import CollapsibleResults from "./CollapsibleResults";
import { useNavigate } from "react-router-dom";
import { CSVLink } from 'react-csv';
import { useEffect } from "react";
import jwtDecode from "jwt-decode";

export default function FindTrans({ progress, domlecServer, updateIsAuthenticated }) {
    const [loading, setLoading] = useState(false); //determines when to show the spinning/loading wheel
    const [documents, setDocuments] = useState([]); //stores retrieved mongo data
    const resultMessageRef = useRef(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [searchAvailable, setSearchAvailable] = useState(false)


    const navigate = useNavigate();

    function setDate(whichDate, newDate) {
        console.log(`date is ${whichDate}`)
        switch (whichDate) {
            case "fromDate": {
                setFromDate(newDate);
                break;
            }
            case "toDate": {
                setToDate(newDate);
                break;
            }
            default: { }
        }

        resultMessageRef.current.innerHTML = '';
        setDocuments([])

    }

    function handleClickP() {
        const fromDateObj = fromDate.toDate();
        const toDateObj = toDate.toDate();

        //from date midnight 
        fromDateObj.setHours(0);
        fromDateObj.setMinutes(0);
        fromDateObj.setSeconds(0);

        //to date 11:59:59pm
        toDateObj.setHours(23);
        toDateObj.setMinutes(59);
        toDateObj.setSeconds(59);

        const epochFrom = Math.floor(fromDateObj.getTime() / 1000);
        const epochTo = Math.floor(toDateObj.getTime() / 1000);

        console.log(`searching for ${fromDateObj} to ${toDateObj}`)
        console.log(`epoch from ${epochFrom} to ${epochTo}`)
        //disable the search button while we are searching....i.e. don't allow repeated pressing of the button

        setLoading(true);

        const token = localStorage.getItem('token');

        const options = {
            method: 'POST',
            url: `${domlecServer}/documents`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },

            data: {
                fromDate: epochFrom,
                toDate: epochTo
            }
        };

        Axios.request(options).then((response) => {
            if (response.status === 200) {
                setDocuments(response.data.results)
                setLoading(false);
                resultMessageRef.current.innerHTML = `Transactions found: ${response.data.count}`
            }
            else if (response.status === 204) {
                setLoading(false);
                resultMessageRef.current.innerHTML = 'No transactions found';
            }
            else { //token expired
                localStorage.removeItem('token');
                updateIsAuthenticated(false)
                //update progress
                navigate('/login') //go to the login page
            }
            setLoading(false);
        }).catch((AxiosError) => {
            localStorage.removeItem('token');
            updateIsAuthenticated(false)
            navigate('/login') //redirect to the login page
            console.log(`Axios Error: ${AxiosError}`)
        }).catch((error) => {
            console.error(error);
            setLoading(false);
            alert('Unable to access data...try again later');
        });

    }

    useEffect(() => {
        let decodedToken
        try {
            decodedToken = jwtDecode(localStorage.getItem('token'));
        } catch (error) {
            console.log('invalid token')
        }
        if (decodedToken.changeRequired) {
            //console.log(`password update required ${changeRequired}`)
            navigate('/password')
        }
    });

    useEffect(() => {
        //validate the dates and enable/disable the search button
        if (fromDate !== null && toDate !== null && toDate >= fromDate) {
            setSearchAvailable(true)
        }
        else {
            setSearchAvailable(false)
        }
    }, [toDate, fromDate])

    return (
        <>
            <div className='row  overflow-transactions'>
                <div className='row'>
                    <div className='twelve columns'>
                        <h4>FIND TRANSACTIONS BY DATE</h4>
                        <h5>Use this form to retrieve a list of transactions by date</h5>
                    </div>
                    <div className="twelve columns">
                        <label >Select the start and end dates</label>

                        <div className="one column">
                            From
                        </div>
                        <div className="elevent columns date-picker">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    onChange={(newDate) => setDate('fromDate', newDate)}
                                    value={fromDate}
                                    format="DD/MM/YYYY"
                                />
                            </LocalizationProvider>

                        </div>
                        <div className="one column">
                            To
                        </div>
                        <div className="elevent columns date-picker">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    onChange={(newDate) => setDate('toDate', newDate)}
                                    value={toDate}
                                    format="DD/MM/YYYY"
                                />
                            </LocalizationProvider>

                        </div>
                        {
                            searchAvailable
                                ? (
                                    <>
                                        <button className="button-primary" onClick={handleClickP}>
                                            <FontAwesomeIcon color='white' icon={faSearch} />
                                            <span>Find Transactions</span>
                                        </button>
                                        <span className='spinner'>
                                            <SpinnerInfinity enabled={loading} size={50} thickness={180} speed={100} color="#36ad47" secondaryColor="rgba(0, 0, 0, 0.44)" />
                                        </span>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <button disabled className="button-primary" onClick={handleClickP}>
                                            <FontAwesomeIcon color='white' icon={faSearch} />
                                            <span>Find Transactions</span>
                                        </button>
                                        <span className='spinner'>
                                            <SpinnerInfinity enabled={loading} size={50} thickness={180} speed={100} color="#36ad47" secondaryColor="rgba(0, 0, 0, 0.44)" />
                                        </span>
                                    </>
                                )

                        }

                    </div>
                </div>
                <div className='row'>
                    <div className="twelve columns">
                        <span className=" u-pull-left result-count" ref={resultMessageRef}></span>

                        {documents.length > 0
                            ? (
                                <span className="u-pull-right button-primary button-primary-link">
                                    <CSVLink
                                        data={documents}
                                        filename={'data.csv'}
                                        target='#'
                                    >
                                        <FontAwesomeIcon color='white' icon={faDownload} />

                                    </CSVLink>
                                </span>
                            )
                            : ''
                        }

                    </div>
                    {documents.length > 0
                        ? (
                            <>
                                <div className="row">
                                    <div className="twelve columns ">
                                        {documents.map((item, index) => (
                                            <div key={item._id}>
                                                <CollapsibleResults key={index} pos={index + 1} {...item} />
                                            </div>
                                        ))}

                                    </div>
                                </div></>
                        )
                        : ''
                    }
                </div >
            </div >
        </>


    )
}