import { useRef } from 'react';
import Domlec from './Domlec';
import Card from './Card';

import DomlecPayment from './DomlecPayment';
import TransactionComplete from './TransactionComplete';


function Home({ progress, updateProgressFromParent, domlecServer, card,
  updateCardFromParent, domlec, updateDomlecFromParent }) {
  //this state tracks the progress

  const makePaymentRef = useRef(null);
  const validateButtonRef = useRef(null);


  /**** for production */
  //to build:
  //npm run build

  //to listen:
  //serve -s build -p 8888
  //better to serve with the express js server

  /***** for development */
  //npm start



  function updateProgress(newProgress) {
    updateProgressFromParent(newProgress)
  }

  function updateDomlec(newDomlec) {
    updateDomlecFromParent(newDomlec);

  }

  function updateCard(newCard) {
    updateCardFromParent(newCard);
  }


  /*
  step0 = always true (default state)
  step1 = validate button available for search
  step2 = customer found so display card information
  step3 = all data entered so enable make payment button
  step4 = show transaction result
  */


  return (
    <div className='overflow-transactions'>
      {
        progress.step4 ? (
          <>
            <div className="row">
              <TransactionComplete
                domlec={domlec}
                updateCard={updateCard}
                updateDomlec={updateDomlec}
                updateProgress={updateProgress}
              />
            </div>
          </>
        )
          :
          ''
      }
      {

        progress.step0 ? (
          <>
            <div className="row">
              <Domlec
                domlecServer={domlecServer}
                progress={progress}
                updateProgress={updateProgress}
                domlec={domlec}
                updateDomlec={updateDomlec}
                validateButtonRef={validateButtonRef}
                updateCard={updateCard}
              />

            </div>
            <div className="row">
              {

                progress.step2 ?
                  <Card
                    domlec={domlec}
                    progress={progress}
                    updateProgress={updateProgress}
                    card={card}
                    updateCard={updateCard}
                    makePaymentRef={makePaymentRef}
                  />
                  : ' '
              }
              {

                progress.step2 ?
                  <DomlecPayment
                    domlecServer={domlecServer}
                    progress={progress}
                    updateProgress={updateProgress}
                    domlec={domlec}
                    updateDomlec={updateDomlec}
                    card={card}
                    updateCard={updateCard}
                    makePaymentRef={makePaymentRef}
                  />
                  : ' '
              }
            </div>

          </>
        )
          :
          ''
      }
    </div>
  );
}

export default Home;
