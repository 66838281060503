import React from 'react';
import cards from './images/payment_icons.png';

export default function Footer() {

    return (
        <>
            <div className='footer'>
                <div className='row'>
                    <div className='twelve columns'>
                        <div>
                            <img
                                src={cards}
                                alt={'Visa and Mastercard logos'}
                            />
                        </div>
                    </div>

                </div >
                <div className='row'>
                    <div className='twelve columns'>
                        <div>
                            © 2023 Dominica Electricity Services Limited. All Rights Reserved
                        </div>
                    </div>

                </div >
            </div>
        </>

    );
};
